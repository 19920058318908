<template>
  <b-container fluid class="component-reports-balance-risk-scale-value-prognosis-chart">
    <line-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'component-reports-balance-risk-scale-value-prognosis-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </line-chart>
  </b-container>
</template>

<script>
import LineChart from '@/components/chartjs/LineChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import defaultChartPeriodMixin from '@/mixins/defaultChartPeriodMixin'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'RiskScaleValuePrognosisChart',
  components: { LineChart },
  mixins: [chartOptions, defaultChartColorPalettes, defaultChartPeriodMixin],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    interest: { type: Number, default: null },
    endYear: { type: Number, default: (new Date()).getFullYear() + 50 },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.balance.risk_scale.table.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }
      const labels = []
      const suffix = ' ' + this.currencySymbol
      let interest = this.apiData.totals.expected_weighted_return / 100

      this.chartOptions.legend = false
      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        return data.datasets[tooltipItem.datasetIndex].label + ': ' + formatNumberToFull(tooltipItem.yLabel.toFixed(0)) + suffix
      }

      if (this.interest !== null) {
        interest = this.interest / 100
      }

      const dataset = {
        backgroundColor: this.getGreen(),
        type: 'line',
        label: this.$t('reports.balance.risk_scale.table.charts.period-weighted-risk-scale'),
        data: []
      }

      const iStartYear = new Date().getFullYear()
      for (let iYear = 0; iYear <= this.endYear - iStartYear; iYear++) {
        labels.push(iYear + iStartYear)
        const iAmount = this.apiData.totals.closing_balance * Math.pow(interest + 1, iYear)
        dataset.data.push(iAmount)
      }

      this.chartData = {
        labels: labels,
        datasets: [dataset]
      }
    }
  }
}
</script>
