<template>
  <b-container fluid class="component-reports-balance-risk-scale-area-percentage-chart">
    <line-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'component-reports-balance-risk-scale-area-percentage-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </line-chart>
  </b-container>
</template>

<script>
import LineChart from '@/components/chartjs/LineChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import defaultChartPeriodMixin from '@/mixins/defaultChartPeriodMixin'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'RiskScaleTotalAreaPercentageChart',
  components: { LineChart },
  mixins: [chartOptions, defaultChartColorPalettes, defaultChartPeriodMixin],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.balance.risk_scale.table.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }
      const labels = []

      this.chartOptions.scales.yAxes[0].stacked = true
      this.chartOptions.scales.yAxes[0].ticks.max = 100
      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return value + ' %'
      }

      this.apiData.meta.periods.map(period => {
        labels.push(this.getTimeLabelFromPeriodLabel(period, this.resolution))
      })

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        return data.datasets[tooltipItem.datasetIndex].label + ': ' + formatNumberToFull(tooltipItem.yLabel) + ' %'
      }

      const datasets = []
      const palette = this.getChartPalette()

      for (const sRiskScale in this.apiData.risk_scale) {
        const dataset = {
          backgroundColor: palette.shift(),
          type: 'line',
          label: this.$t('common.asset_liabilities.risk_scale.' + sRiskScale),
          data: []
        }

        for (const period in this.apiData.risk_scale[sRiskScale].period_percentages) {
          dataset.data.push(this.apiData.risk_scale[sRiskScale].period_percentages[period].toFixed(1))
        }

        if (sRiskScale !== 'not_set') {
          datasets.push(dataset)
        } else if (this.apiData.meta.is_uncategorized_non_zero && sRiskScale === 'not_set') {
          datasets.push(dataset)
        }
      }

      this.chartData = {
        labels: labels,
        datasets: datasets
      }
    }
  }
}
</script>
