<template>
  <div class="component-risk-scale-total-table">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col>
                  <h2>{{ $t(translationPath + 'title') }}</h2>
                </b-col>
              </b-row>
            </template>
            <div class="col-lg-8 pl-0">
              <div v-html="$t(translationPath + 'description', { balance: isApiDataLoaded ? amountFormatter(apiData.totals.closing_balance) : '', weighted_return: isApiDataLoaded ? apiData.totals.expected_weighted_return : '' })"></div>
            </div>
            <div class="col-lg-8 pl-0">
              <b-form-group
                label-for="end_year"
                label-cols-md="2"
                label-class="pt-3 font-weight-bold"
              >
                <template slot="label">{{ $t(translationPath + 'label_end_year') }}</template>
                <template slot="description">
                  {{ $t(translationPath + 'description_end_year') }}
                </template>

                <number-input
                  slider
                  v-model="endYear"
                  id="end_year"
                  :min="(new Date()).getFullYear()"
                  :max="(new Date()).getFullYear() + 80"
                  :step="1"
                />

              </b-form-group>

              <b-form-group
                label-for="interest"
                label-cols-md="2"
                label-class="pt-3 font-weight-bold"
              >
                <template slot="label">{{ $t(translationPath + 'label_interest') }}</template>
                <template slot="description">
                  {{ $t(translationPath + 'description_interest', { interest: isApiDataLoaded ? apiData.totals.expected_weighted_return : '' }) }}
                </template>

                <percentage-input
                  id="interest"
                  v-model="interest"
                  slider
                  :precision="2"
                  :step="0.5"
                  :max="20"
                />

              </b-form-group>

              <span class="btn btn-primary" @click="onCalculate">Calculate</span>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'
import PercentageInput from '@/components/common/PercentageInput'
import NumberInput from '@/components/common/NumberInput'
import _ from 'lodash'

export default {
  name: 'RiskScaleTotalTable',
  components: { PercentageInput, NumberInput },
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    initialFormData: {
      type: Object,
      default: () => {
        return {
          interest: 2,
          endYear: 2100
        }
      }
    }
  },
  data () {
    return {
      translationPath: 'reports.balance.risk_scale.value_prognosis.',
      tableData: [],
      tableColumns: [],
      interest: null,
      endYear: null
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    isApiDataLoaded () {
      if (!this.apiData) {
        return false
      }
      return Object.prototype.hasOwnProperty.call(this.apiData, 'totals')
    }
  },
  created () {
    // console.log(this.initialFormData)
    if (Object.prototype.hasOwnProperty.call(this.initialFormData, 'interest')) {
      this.interest = this.initialFormData.interest
    }
    if (Object.prototype.hasOwnProperty.call(this.initialFormData, 'endYear')) {
      this.endYear = this.initialFormData.endYear
    }
    this.debounceEmit = _.debounce(this.onCalculate, 600)
  },
  methods: {
    amountFormatter (value) {
      return this.currentCOA.locale ? formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 0) : Math.ceil(value)
    },
    percentageFormatter (value) {
      return parseFloat(value).toFixed(2).toString() + ' %'
    },
    onCalculate () {
      if (this.isLoaded) {
        this.$emit('form-submit', { endYear: this.endYear, interest: this.interest })
      }
    }
  },
  watch: {
    apiData: {
      handler: function () {
        if (this.isApiDataLoaded) {
          this.interest = this.apiData.totals.expected_weighted_return
        }
      },
      deep: true,
      immediate: true
    },
    endYear: {
      handler () {
        this.debounceEmit()
      }
    },
    interest: {
      handler () {
        this.debounceEmit()
      }
    }
  }
}
</script>

<style lang="scss">
.component-productive-assets-total-table{
}
</style>
