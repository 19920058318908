<template>
  <div class="component-risk-scale-total-table">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col>
                  <h2>{{ $t(translationPath + 'total_table_title', { start_date: startDate, end_date: endDate }) }}</h2>
                </b-col>
              </b-row>
            </template>
            <div class="col-lg-8 pl-0">
              <div v-html="$t(translationPath + 'description_total_table')"></div>
            </div>

            <h3>{{$t(translationPath + 'heading_kpi_overview')}}</h3>
            <ul>
              <li v-show="isLoaded" v-html="$t(translationPath + 'kpi_total_weigthted_riskscale', { weighted_riskscale: isApiDataLoaded ? apiData.totals.weighted_riskscale.toFixed(2) : '' })"></li>
              <li v-show="isLoaded">{{ $t(translationPath + 'kpi_expected_weighted_return' )}}: <b>{{ isApiDataLoaded ? apiData.totals.expected_weighted_return : '' }} %</b></li>
              <li v-show="isLoaded">{{ $t(translationPath + 'kpi_total_outgoing_balance') }}: <b>{{ isApiDataLoaded ? amountFormatter(apiData.totals.closing_balance) : '' }}</b></li>
            </ul>

            <h3>{{$t(translationPath + 'heading_risk_scale_table')}}</h3>
            <b-table
              class="spirecta-simple-table productive-asset-period-table-amounts mb-3"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              :items="tableData"
              :fields="tableColumns"
              :busy="!isLoaded"
              :tbodyTrClass="trClass"
            >
              <template v-slot:table-busy>
                <loader/>
              </template>

              <!-- END CUSTOM SLOTS -->
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader'
import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'

export default {
  name: 'RiskScaleTotalTable',
  components: { Loader },
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      translationPath: 'reports.balance.risk_scale.table.',
      tableData: [],
      tableColumns: []
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    isApiDataLoaded () {
      if (!this.apiData) {
        return false
      }
      return Object.prototype.hasOwnProperty.call(this.apiData, 'totals')
    }
  },
  methods: {
    toggleChart (showChart) {
      this.showChart = showChart
      this.$emit('chart-to-display', { from_tab: 'summary', chart: showChart })
    },
    buildTableDataRows () {
      const rows = []

      if (this.apiData) {
        for (const idx in this.apiData.risk_scale) {
          rows.push({
            title: this.$t('common.asset_liabilities.risk_scale.' + idx),
            opening_balance: this.apiData.risk_scale[idx].period_balances.opening_balance,
            period_change: this.apiData.risk_scale[idx].period_change,
            closing_balance: this.apiData.risk_scale[idx].closing_balance,
            closing_percentage: this.apiData.risk_scale[idx].closing_percentage,
            row_type: 'data'
          })
        }
        rows.push({
          title: this.$t('common.sum'),
          opening_balance: this.apiData.totals.period_balances.opening_balance,
          period_change: this.apiData.totals.period_change,
          closing_balance: this.apiData.totals.closing_balance,
          closing_percentage: '100 %',
          row_type: 'total'
        })
      }

      return rows
    },
    buildTableColumns () {
      const cols = [
        { key: 'title', label: this.$t(this.translationPath + 'th_risk_scale') },
        { key: 'opening_balance', label: this.$t('reports.balance.common.opening_balance'), class: 'text-right', formatter: this.amountFormatter },
        { key: 'period_change', label: this.$t('reports.balance.common.period_change'), class: 'text-right', formatter: this.amountFormatter },
        { key: 'closing_balance', label: this.$t('reports.balance.common.closing_balance'), class: 'text-right', formatter: this.amountFormatter },
        { key: 'closing_percentage', label: this.$t('reports.balance.common.closing_balance_percentage'), class: 'text-right', formatter: this.percentageFormatter }
      ]

      return cols
    },
    amountFormatter (value) {
      return this.currentCOA.locale ? formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 0) : Math.ceil(value)
    },
    percentageFormatter (value) {
      return parseFloat(value).toFixed(1).toString() + ' %'
    },
    trClass (item) {
      if (!item) {
        return ''
      }
      if (Object.prototype.hasOwnProperty.call(item, 'row_type')) {
        return 'tr-' + item.row_type.replace(/_/, '-')
      }

      return ''
    }
  },
  watch: {
    apiData: {
      handler: function () {
        this.tableColumns = this.buildTableColumns()
        this.tableData = this.buildTableDataRows()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-productive-assets-total-table{
}
</style>
