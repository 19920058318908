<template>
  <b-container fluid class="component-reports-balance-risk-scale-weighted-riskscale-chart">
    <line-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'component-reports-balance-risk-scale-weighted-riskscale-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </line-chart>
  </b-container>
</template>

<script>
import LineChart from '@/components/chartjs/LineChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import defaultChartPeriodMixin from '@/mixins/defaultChartPeriodMixin'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'RiskScaleAreaWeightedRiskScaleChart',
  components: { LineChart },
  mixins: [chartOptions, defaultChartColorPalettes, defaultChartPeriodMixin],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.balance.risk_scale.table.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }
      const labels = []
      this.chartOptions.legend = false
      this.chartOptions.scales.yAxes[0].ticks.max = 7
      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return value
      }
      this.apiData.meta.periods.map(period => {
        labels.push(this.getTimeLabelFromPeriodLabel(period, this.resolution))
      })

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        return data.datasets[tooltipItem.datasetIndex].label + ': ' + formatNumberToFull(tooltipItem.yLabel)
      }

      const datasets = []

      const dataset = {
        backgroundColor: this.getYellow(),
        type: 'line',
        label: this.$t('reports.balance.risk_scale.table.charts.period-weighted-risk-scale'),
        data: []
      }

      for (const period in this.apiData.totals.period_weighted_riskscale) {
        dataset.data.push(parseFloat(this.apiData.totals.period_weighted_riskscale[period]).toFixed(2))
      }
      datasets.push(dataset)

      this.chartData = {
        labels: labels,
        datasets: datasets
      }
    }
  }
}
</script>
