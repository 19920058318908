<template>
  <b-container fluid class="component-reports-balance-risk-scale-multi-series-pie-chart">
    <pie-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'reports-balance-risk-scale-multi-series-pie-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </pie-chart>
  </b-container>
</template>

<script>
import PieChart from '@/components/chartjs/DoughnutChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import chartPalettes from '@/mixins/defaultChartColorPalettes'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'RiskScaleMultiSeriesPieChart',
  components: { PieChart },
  mixins: [chartOptions, chartPalettes],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.balance.risk_scale.table.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      const aRiskScaleLabels = []
      const aTmpRiskScaleAssets = { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], not_set: [] }
      const currencySymbol = this.currencySymbol ? this.currencySymbol : 'kr'
      const oDatasetAssets = { data: [], backgroundColor: [] }
      const oDatasetRiskScale = { data: [], backgroundColor: [] }
      let oPalette = this.getChartPalette()

      /* Modify the Y-Axis */
      this.chartOptions.scales.yAxes[0].display = false
      this.chartOptions.scales.xAxes[0].display = false
      this.chartOptions.legend.display = true

      /* Make half circle */
      this.chartOptions.rotation = -Math.PI
      this.chartOptions.circumference = Math.PI

      /* Tooltip */
      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, chartData) {
        const fAmount = chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
        let iLabelIndex = tooltipItem.index
        if (tooltipItem.datasetIndex === 1) {
          iLabelIndex += chartData.datasets[0].data.length
        }
        const sTitle = chartData.labels[iLabelIndex]

        return sTitle + ': ' + formatNumberToFull(fAmount) + ' ' + currencySymbol
      }

      /**
       * Build dataset
       */
      // Step 1. Prepare for sorting by merging into one array
      let sTmpColor = null
      for (const sRiskScale in this.apiData.accounts) {
        sTmpColor = oPalette.shift()
        for (const iAccountId in this.apiData.accounts[sRiskScale]) {
          if (this.apiData.accounts[sRiskScale][iAccountId].balances.closing_balance !== 0) {
            aTmpRiskScaleAssets[sRiskScale].push({
              title: this.apiData.accounts[sRiskScale][iAccountId].label,
              amount: this.apiData.accounts[sRiskScale][iAccountId].balances.closing_balance,
              color: sTmpColor
            })
          }
        }
      }

      // Step 2. Sort according to amount for better charts
      for (const sRiskScale in this.apiData.accounts) {
        aTmpRiskScaleAssets[sRiskScale].sort(function cmp (a, b) {
          return b.amount - a.amount
        })
      }

      // Step 3. Add to dataset (outer dataset)
      for (const sRiskScale in aTmpRiskScaleAssets) {
        for (const idx in aTmpRiskScaleAssets[sRiskScale]) {
          aRiskScaleLabels.push(aTmpRiskScaleAssets[sRiskScale][idx].title)
          oDatasetAssets.data.push(aTmpRiskScaleAssets[sRiskScale][idx].amount)
          oDatasetAssets.backgroundColor.push(aTmpRiskScaleAssets[sRiskScale][idx].color)
        }
      }

      // Step 4. Add risk_scales (inner dataset)
      oPalette = this.getChartPalette()
      for (const sRiskScale in this.apiData.risk_scale) {
        oDatasetRiskScale.data.push(this.apiData.risk_scale[sRiskScale].closing_balance)
        oDatasetRiskScale.backgroundColor.push(oPalette.shift())

        if (sRiskScale !== 'not_set') {
          aRiskScaleLabels.push(this.$t('common.asset_liabilities.risk_scale.' + sRiskScale))
        } else if (this.apiData.meta.is_uncategorized_non_zero && sRiskScale === 'not_set') {
          aRiskScaleLabels.push(this.$t('common.asset_liabilities.risk_scale.' + sRiskScale))
        }
      }

      /* Prepare data for chart */
      this.chartData = {
        labels: aRiskScaleLabels,
        datasets: [oDatasetAssets, oDatasetRiskScale]
      }
    }
  },
  watch: {
    apiData () {
      this.fillChart()
    }
  },
  created () {
    this.fillChart()
  }
}
</script>
