<template>
  <div class="component-risk-scale-list-of-assets-table">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col>
                  <h2>{{ $t(translationPath + 'title_list_of_assets_table', { start_date: startDate, end_date: endDate }) }}</h2>
                </b-col>
                <b-col cols="4" class="text-right">
                  <table-column-toggler
                    :options="{ asset_group: true, type: true }"
                    :option-values="toggleColumnsOptions"
                    @column-to-toggle="onColumnToggle"
                  />
                </b-col>
              </b-row>
            </template>
            <div class="col-lg-8 pl-0">
              <div v-html="$t(translationPath + 'description_list_of_assets_table')"></div>
            </div>

            <b-table
              class="spirecta-simple-table mb-0"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              :items="tableData"
              :fields="tableColumns"
              :busy="!isLoaded"
              :filter="filter"
              :filter-included-fields="['title','title_with_code', 'type']"
              :tbodyTrClass="trClass"
            >
              <!-- LOADER -->
              <template v-slot:table-busy>
                <loader/>
              </template>

              <!-- FILTER -->
              <template v-slot:top-row>
                <td :colspan="tableColumns.length">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-search text-secondary"></i>
                    <b-form-input v-model="filter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                  </div>
                </td>
              </template>

              <!-- START CUSTOM SLOTS -->

              <!-- title -->
              <template v-slot:cell(title)="data">
                <template v-if="data.item.row_type === 'subtotal' || data.item.row_type === 'risk_class' || data.item.row_type === 'total'">
                  {{data.value}}
                </template>
                <template v-else-if="data.item.row_type === 'asset'">
                  <b-link :to="'/reports/performance/accounts/'+data.item.account_id+'/view?account_type=asset&start_date='+startDate+'&end_date='+endDate">{{data.value}}</b-link>
                </template>
              </template>

              <!-- expected_annual_return -->
              <template v-slot:cell(expected_annual_return)="data">
                <template v-if="data.item.row_type !== 'empty' && data.item.row_type !== 'risk_class'">{{data.value}}</template>
              </template>

              <!-- opening_balance -->
              <template v-slot:cell(opening_balance)="data">
                <template v-if="data.item.row_type !== 'empty' && data.item.row_type !== 'risk_class'">{{data.value}}</template>
              </template>

              <!-- period_change -->
              <template v-slot:cell(period_change)="data">
                <template v-if="data.item.row_type !== 'empty' && data.item.row_type !== 'risk_class'">{{data.value}}</template>
              </template>

              <!-- closing_balance -->
              <template v-slot:cell(closing_balance)="data">
                <template v-if="data.item.row_type !== 'empty' && data.item.row_type !== 'risk_class'">{{data.value}}</template>
              </template>

              <!-- percentage_of_risk_class -->
              <template v-slot:cell(percentage_of_risk_class)="data">
                <template v-if="data.item.row_type !== 'empty' && data.item.row_type !== 'risk_class'">{{data.value}}</template>
              </template>

              <!-- percentage_of_total -->
              <template v-slot:cell(percentage_of_total)="data">
                <template v-if="data.item.row_type !== 'empty' && data.item.row_type !== 'risk_class'">{{data.value}}</template>
              </template>

              <!-- END CUSTOM SLOTS -->
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader'
import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'
import TableColumnToggler from '@/views/reports/_inc/TableColumnToggler'

export default {
  name: 'ProductiveAssetsListOfAssetsTable',
  components: { Loader, TableColumnToggler },
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      translationPath: 'reports.balance.productive_assets.table.',
      tableData: [],
      tableColumns: [],
      filter: '',
      toggleColumnsOptions: {
        asset_group: true,
        opening_balance: false,
        period_change: false,
        type: false
      }
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    toggleChart (showChart) {
      this.showChart = showChart
      this.$emit('chart-to-display', { from_tab: 'summary', chart: showChart })
    },
    buildTableDataRows () {
      if (!this.apiData) {
        return []
      }

      const rows = []
      // const oTotalRow = { title: this.$t('common.sum'), opening_balance: 0, period_change: 0, closing_balance: 0, percentage_of_productive_type: 0, percentage_of_total: 0, row_type: 'total' }
      // const oEmpty = { row_type: 'empty' }
      let fPercentageOfRiskClass = 0
      let fPercentageOfTotal = 0

      rows.push({ row_type: 'empty' })

      for (const iRiskScale in this.apiData.accounts) {
        rows.push({ title: this.$t('common.asset_liabilities.risk_scale.' + iRiskScale), row_type: 'risk_class' })
        fPercentageOfRiskClass = 0
        fPercentageOfTotal = 0

        for (const asset in this.apiData.accounts[iRiskScale]) {
          rows.push({
            title: this.apiData.accounts[iRiskScale][asset].label,
            group: this.apiData.accounts[iRiskScale][asset].account_group_name,
            account_id: this.apiData.accounts[iRiskScale][asset].account_id,
            opening_balance: this.apiData.accounts[iRiskScale][asset].balances.opening_balance,
            period_change: this.apiData.accounts[iRiskScale][asset].balances.period_change,
            closing_balance: this.apiData.accounts[iRiskScale][asset].balances.closing_balance,
            percentage_of_risk_class: this.apiData.accounts[iRiskScale][asset].balances.percentage_of_risk_class,
            percentage_of_total: this.apiData.accounts[iRiskScale][asset].balances.percentage_of_total,
            expected_annual_return: this.apiData.accounts[iRiskScale][asset].expected_annual_return,
            type: this.$t('common.asset_liabilities.risk_scale.' + iRiskScale),
            row_type: 'asset'
          })
          fPercentageOfRiskClass += this.apiData.accounts[iRiskScale][asset].balances.percentage_of_risk_class
          fPercentageOfTotal += this.apiData.accounts[iRiskScale][asset].balances.percentage_of_total
        }

        rows.push({
          row_type: 'subtotal',
          title: this.$t('common.partsum'),
          percentage_of_risk_class: fPercentageOfRiskClass + ' %',
          percentage_of_total: fPercentageOfTotal + ' %',
          opening_balance: this.apiData.risk_scale[iRiskScale].period_balances.opening_balance,
          period_change: this.apiData.risk_scale[iRiskScale].period_change,
          expected_annual_return: this.apiData.risk_scale[iRiskScale].expected_weighted_return,
          type: this.$t('common.asset_liabilities.risk_scale.' + iRiskScale),
          closing_balance: this.apiData.risk_scale[iRiskScale].closing_balance
        })
      }

      rows.push({ row_type: 'empty' })

      rows.push({
        row_type: 'total',
        title: this.$t('common.sum').toString(),
        percentage_of_risk_class: '100',
        percentage_of_total: '100',
        opening_balance: this.apiData.totals.period_balances.opening_balance,
        period_change: this.apiData.totals.period_change,
        expected_annual_return: this.apiData.totals.expected_weighted_return,
        closing_balance: this.apiData.totals.closing_balance
      })

      return rows
    },
    buildTableColumns () {
      const cols = [{ key: 'title', label: this.$t(this.translationPath + 'th_assets') }]

      if (this.toggleColumnsOptions.asset_group) {
        cols.push({ key: 'group', label: this.$t(this.translationPath + 'th_group') })
      }

      cols.push({ key: 'expected_annual_return', label: this.$t(this.translationPath + 'expected_annual_return'), class: 'text-right', formatter: this.percentageFormatter })

      if (this.toggleColumnsOptions.opening_balance) {
        cols.push({ key: 'opening_balance', label: this.$t('reports.balance.common.opening_balance'), class: 'text-right', formatter: this.amountFormatter })
      }
      if (this.toggleColumnsOptions.period_change) {
        cols.push({ key: 'period_change', label: this.$t('reports.balance.common.period_change'), class: 'text-right', formatter: this.amountFormatter })
      }

      cols.push({ key: 'closing_balance', label: this.$t('reports.balance.common.closing_balance'), class: 'text-right', formatter: this.amountFormatter })
      cols.push({ key: 'percentage_of_risk_class', label: this.$t(this.translationPath + 'th_percentage_of_type'), class: 'text-right', formatter: this.percentageFormatter })

      if (this.toggleColumnsOptions.type) {
        cols.push({ key: 'type', label: this.$t(this.translationPath + 'th_type') })
      }

      cols.push({ key: 'percentage_of_total', label: this.$t(this.translationPath + 'th_percentage_of_total'), class: 'text-right', formatter: this.percentageFormatter })

      return cols
    },
    onColumnToggle (column) {
      this.toggleColumnsOptions[column] = !this.toggleColumnsOptions[column]
      this.tableColumns = this.buildTableColumns()
    },
    amountFormatter (value) {
      return this.currentCOA.locale ? formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 0) : Math.ceil(value)
    },
    percentageFormatter (value) {
      return parseFloat(value).toFixed(1).toString() + ' %'
    },
    trClass (item) {
      if (!item) {
        return ''
      }
      if (Object.prototype.hasOwnProperty.call(item, 'row_type')) {
        return 'tr-' + item.row_type.replace(/_/, '-')
      }

      return ''
    }
  },
  watch: {
    apiData: {
      handler: function () {
        this.tableColumns = this.buildTableColumns()
        this.tableData = this.buildTableDataRows()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-risk-scale-list-of-assets-table{
  tr.tr-productive-type td:nth-child(1){
    font-weight: bold;
  }

  tr.tr-subtotal td{
    font-weight: bold;
  }

  tr.tr-risk-class td{
    background: white;
    border-top:0;
    font-weight: bold;
    padding-top:20px;
  }
}
</style>
